<template>
  <v-container>
    <p> Climbing routes we have done :) </p>

    <div id="app">
 
    <v-data-table
      :headers="headers"
      :items="desserts"
      :items-per-page="5"
      class="elevation-1"
    ></v-data-table>

</div>

  </v-container>
  
</template>

<script>
  export default {
    name: 'HelloWorld',

    data: () => ({
      
       headers: [
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        { text: 'Grade', value: 'grade' },
        { text: 'Rating', value: 'rating' },
        { text: 'Tries', value: 'tries' },
        { text: 'Length (m) ', value: 'length' },
        { text: 'Bolting', value: 'bolting' },
      ],
      desserts: [
        {
          name: 'Frozen Yogurt',
          grade: '7b+',
          rating: 5,
          tries: 'flash',
          length: 25,
          bolting: 5,
        },
        {
          name: 'Ice cream Oreo',
           grade: '5c',
          rating: 5,
          tries: 'flash',
          length: 2,
          bolting: 5,
        },
        
      ],
    }),
  }
</script>
